import { render, staticRenderFns } from "./post-card.vue?vue&type=template&id=299231f7&scoped=true&"
import script from "./post-card.vue?vue&type=script&lang=ts&"
export * from "./post-card.vue?vue&type=script&lang=ts&"
import style0 from "./post-card.vue?vue&type=style&index=0&id=299231f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "299231f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VHover,VImg,VOverlay,VSheet,VSpacer,VTooltip})
